.loader{
    background-color:rgba(0,0,0,0.95);
    position:fixed;
    width:100%;
    height:100%;
    left: 0;
    top: 0;
    z-index:1500;
    align-items:center;
    overflow-y: scroll;
}