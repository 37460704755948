
body,html {
  margin:0;
  min-height: 100%;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  text-align: center;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  min-height: 100%;
  margin: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-repeat: no-repeat;
  background-size: cover !important;
  background-attachment: fixed;
  -webkit-font-smoothing: antialiased;
  padding: 0;
  z-index: 0;
  font-weight: 400;
  background-color: #f5f6fb;
  -moz-osx-font-smoothing: grayscale;
  background: rgb(215,255,238);

  background: linear-gradient(180deg, rgb(215, 250, 255) 0%, rgb(151, 200, 233) 50%, rgb(88, 109, 201) 100%);
  height: 100%;
  background: url('Images/bck.png') no-repeat center/cover;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

:root {
  --amplify-primary-color: #0069d9;
  --amplify-primary-tint: #0069d9;
  --amplify-primary-shade: #0069d9;
}

.tabView {
  margin: auto;
  /*padding: 2rem 10px;
  padding-top: 10px;
  padding-bottom: 0px;*/
  display: flex;
  justify-content: center;
}

.paginationView {
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  justify-content: flex-end;
  position: absolute;
  bottom: 0;
  right: 0;
}

.paginationView2 {
  padding-left: 5px;
  padding-right: 10px;
  display: flex;
  justify-content: flex-end;
  height: 100%;
}

.limitView {
  padding-right: 5px;
  padding-left: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
}

.limitLabel {
  font-size: 13px;
  height: 20px;
  padding: 0;
  margin: 5px 0 0 2px;
}

.bottomInfo {
  width: 100%;
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: center;
}

.page-link{
  background-color: rgba(255, 255, 255, 0.2) !important;
  border: 1px solid #cacaca !important;
  color: black !important;
  font-size: 14px;
}

.selectPage {
  width: 50px;
  height: 33px;
  padding-left: 10px;
  padding-right: 0px;
  background-color: rgba(255,255,255,0);
  border: none !important;
  -webkit-text-stroke: thin;
  color: black;
  font-size: 14px;
}

.multiContents {
  bottom: 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-content: stretch;
  z-index: 2;
  width: 100%;
  position: fixed;
}

.tableCheck {
  text-align: center;
  vertical-align: inherit !important;
}

.searchBar {
  height:32px;
  width:40%;
  max-width:340px;
  height: 38px;
}

.selectSearch {
  width:30%;
  max-width:340px;
}

.selectPagination {
  height: 24px;
  max-width: 100%;
  user-select: none;
  padding-left: 1px;
  padding-right: 1px;
  box-sizing: content-box;
  font-size: inherit;
  color: inherit;
  border: none;
  background-color: transparent;
  -moz-appearance: none;
  direction: ltr;
  margin-right: 10px;
}

.inputFilter {
  height: 29px !important;
  background-color: lightgrey !important;
  color: #007bff !important;
}

.inputFilter::placeholder {
  color: #515151 !important;
}

.thTable {
  vertical-align: initial !important;
}

.logo {
  width: 73px;
  height: 73px;
  margin-left:15px
}

.topBar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.topBar2 {
  display: flow-root;
}

.headerText {
  font-size: 26px;
  color: #601779;
  font-weight: bold;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  display: flex;
  letter-spacing: 0.9px;
  align-items: center;
}

.headerText2 {
  color: black;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  display: flex;
  align-items: center;
}

.buttonLeft {
  text-align: left;
}

.buttonColor {
  background-color: #181a38 !important;
  border-color: #181a38 !important;;
  color:white !important;
}

.buttonColor:hover {
  background-color: #2e3264 !important;;
  border-color: #2e3264 !important;;
  color: white !important;
}

.buttonColor:disabled {
  background-color: gray !important;;
  border-color: gray !important;;
  color: lightgray !important;
}

.mainContainer {
  display: flex;
  width: 100%;
  height: 100%;
}

.collapseContainer {
  border: 1px solid rgba(255, 255, 255, 0.1);
  height: 100%;
  padding: 16px;
  flex-grow: 1;
  background: rgba(255, 255, 255, .1);
  box-shadow: 0px 5px 45px rgba(0,0,0,0.2);
  border-radius: 25px;
  backdrop-filter: blur(4px);
}

.menuContainer {
  /*background: rgba(255, 255, 255, .1);
  margin-bottom: 10px;
  margin-left:10px;
  margin-top:10px;
  border-radius: 10px;
  width: 100px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0px 5px 45px rgba(0, 0, 0, 0.2);*/
  width: 120px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  height: 100%;
  background: rgba(255, 255, 255, .1);
  box-shadow: 0px 5px 45px rgba(0,0,0,0.2);
  margin-right: 16px;
  border-radius: 25px;
  display: block;
  min-height: 600px !important;
}

.collapsing {
  transition: none !important;
}

.menuButton {
  display: flex;
  flex-direction:column;
  bottom: 10px;
  padding: 12px !important;
}

.labelButtonMenu {
  color:black;
  font-size:14px !important;
}

.marginTop {
  margin-top: 8px !important;
}

.glassContainer {
  top: 2vh;
  min-height: 94vh;
  width: 98vw;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 70px auto;
  border-radius: 25px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: 20px 20px 35px rgba(0, 0, 0, 0.5);
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 40px;
}

.pageContentWrapper {
  /*backdrop-filter: blur(4px);
  background: rgba(255, 255, 255, .1);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 25px;
  height: 100%;
  flex-grow: 1;
  box-shadow: 0px 5px 45px rgba(0, 0, 0, 0.2);
  margin: auto;
  margin-top: 5px;*/
  gap: 13px;
  top: 2vh;
  width: 98vw;
  border: 1px solid rgba(255, 255, 255, 0.2);
  display: grid;
  padding: 16px;
  position: relative;
  box-shadow: 20px 20px 35px rgba(0, 0, 0, 0.5);
  min-height: 96vh;
  margin-left: auto;
  margin-right: auto;
  border-radius: 25px;
  backdrop-filter: blur(4px);
  grid-template-rows: auto 1fr;
  grid-template-columns: auto;
}

.qc_chart {
  position: relative;
  width: 60px;
}
.qc_chart_bg_wrapper {
  width: 60px;
}

.qc_chart_wrapper {
  display: flex;
  align-items: flex-end;
  width: 25%;
  margin: 37px 0 0 0px;
  position: relative;
  right:3px;
}

.qc_chart_val {
  font-size: 10px;
  font-weight: bold;
  padding: 0 1px 0 1px;
  position: relative;
  top: 14px;
  right: 10px;
}

.qc_chart_val_left {
  left: 27px;
}

.qc_chart_lable {
  margin: 12px 0 0 0;
  font-size: 14px;
  width: 100%;
  text-align: center;
}

.qc_chart_bg {
  position: absolute;
  bottom: 0;
  left: 10px;
  width: 40px;
  height: 36px;
  background-image: linear-gradient(white, white), linear-gradient(110deg, #008000 0%, #ffa500 65%, #ff0000 81%);
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  border: solid 45px transparent;
  border-radius: 180px 180px 0px 0px;
  border-width: 33px 33px 0px 33px;
}

.qc_chart_line {
  position: absolute;
  left: 8px;
  width: 35px;
  opacity: 0.9;
  content: "";
  height: 2px;
  background-color: #ffffff;
  border-radius: 2px;
  transform: rotate(0deg);
  transform-origin: bottom right;

  animation-name: example;
  animation-duration: 2s;
}

.qc_chart_val_wrapper {
  position: absolute;
  bottom: 0;
  margin: 0 0 0 27px;
  width: 33px;
  height: 19px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  border-radius: 50px 50px 0px 0px;
  background-image: linear-gradient(white, white);
}

.qc_chart_main_val {
  font-size: 11px;
  font-weight: bold;
}

.qc_chart_val_right {
  right: 10px;
}

.tableHeader {
  background-color: rgba(255, 255, 255, 0.3);
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.tableDiv {
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  /*box-shadow: 0px 5px 10px 0px #637898;*/
  box-shadow: 0px 0px 40px rgba(0,0,0,0.3);
  border-radius: 10px;
  background-color: rgba(255,255,255, 0.3);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

th:first-child {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

th:last-child {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

:root {
  --amplify-primary-color: #181a38 ;
  --amplify-primary-tint: #2e3264;
  --amplify-primary-shade: #2e3264;
}

amplify-sign-out {
  border-radius: 4px !important;
}

.divParent {
  display:flex;
  flex-direction:column;
  height: 100%;
  padding-bottom: 10px;
}

.tableOverflow {
  overflow: auto;
  position: absolute;
  width: 100%;
  height: 84%;
}

.dialogTitle {
  text-align: center;
  color: white;
  background: #00a6eb;
  font-size: 26px;
  letter-spacing: 0.6px;
}

.MuiSelect-root {
  width: 100%;
}

.backgroundDialog {
  backdrop-filter: blur(5px);
}

.dialogPaperProps {
  background-color: rgba(255,255,255, 0.6);
  border-radius: 10px;
}

.MuiDialog-paper {
  background-color: rgba(255,255,255, 0.6) !important;
  border-radius: 10px !important;
  min-width: 300px !important;
}